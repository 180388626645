import React from "react";
import { Typography, List, ListItem, ListItemText } from "@mui/material";

function PrivacyPolicies() {
  return (
    <div className="policy-container">
      <Typography variant="h4" gutterBottom className="terms-top-header">
        Privacy Policies
      </Typography>

      <Typography className="terms-body-text">
        Last Updated: 8/22/2024
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        1. Introduction
      </Typography>
      <Typography className="terms-body-text">
        Welcome to NUUN (the "App"). We are committed to protecting your privacy
        and ensuring that your personal information is handled in a safe and
        responsible manner. This Privacy Policy outlines how we, Asana
        Entertainment LTD, collect, use, and safeguard your information when you
        use our App.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        2. Information We Collect
      </Typography>
      <Typography className="terms-body-text">
        We may collect the following types of information:
      </Typography>
      <List className="terms-body-text">
        <ListItem>
          <ListItemText
            primary="Personal Information: When you create an account,
          sign up for events, or contact us, we may collect information such as
          your name, email address, phone number, and payment details."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Event Information: If you create or attend an event,
          we may collect details about the event, including location, date,
          time, and any images or descriptions you provide."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Usage Data: We may collect information on how you use
          the App, including your device type, operating system, IP address, and
          activity within the App (e.g., pages visited, features used)."
          />
        </ListItem>
      </List>

      <Typography variant="h5" gutterBottom className="terms-header">
        3. How We Use Your Information
      </Typography>
      <Typography className="terms-body-text">
        We use the information we collect to:
      </Typography>
      <List className="terms-body-text">
        <ListItem>
          <ListItemText primary="Provide and improve the App's functionality and user experience." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Process event registrations and payments." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Communicate with you regarding your account, events, and updates." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Analyze usage patterns to enhance the App's performance." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Ensure the security and integrity of the App." />
        </ListItem>
      </List>

      <Typography variant="h5" gutterBottom className="terms-header">
        4. Sharing Your Information
      </Typography>
      <Typography className="terms-body-text">
        We do not sell or rent your personal information to third parties. We
        may share your information with:
      </Typography>
      <List className="terms-body-text">
        <ListItem>
          <ListItemText
            primary="Service Providers: Third-party vendors who assist us
          in operating the App, processing payments, or providing customer
          support."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Legal Compliance: If required by law, or if we
          believe disclosure is necessary to protect our rights, enforce our
          policies, or protect the safety of users or others."
          />
        </ListItem>
      </List>

      <Typography variant="h5" gutterBottom className="terms-header">
        5. Data Security
      </Typography>
      <Typography className="terms-body-text">
        We take reasonable precautions to protect your information. However, no
        method of transmission over the internet or electronic storage is 100%
        secure. While we strive to use commercially acceptable means to protect
        your personal information, we cannot guarantee its absolute security.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        6. Your Choices
      </Typography>
      <Typography className="terms-body-text">
        You have the right to:
      </Typography>
      <List className="terms-body-text">
        <ListItem>
          <ListItemText primary="Access and update your personal information within the App." />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Opt-out of receiving marketing communications by following the
          unsubscribe instructions in our emails."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Delete your account and associated data by contacting our support team
          at admin@nuunapp.com"
          />
        </ListItem>
      </List>

      <Typography variant="h5" gutterBottom className="terms-header">
        7. Children's Privacy
      </Typography>
      <Typography className="terms-body-text">
        Our App is not intended for children under the age of 13. We do not
        knowingly collect personal information from children under 13. If we
        become aware that we have collected personal information from a child
        under 13, we will take steps to delete such information.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        8. Changes to This Privacy Policy
      </Typography>
      <Typography className="terms-body-text">
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page and
        updating the "Last Updated" date.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        9. Contact Us
      </Typography>
      <Typography className="terms-body-text">
        If you have any questions or concerns about this Privacy Policy, please
        contact us at: admin@nuunapp.com
      </Typography>
      <Typography className="terms-body-text">
        Asana Entertainment LTD
      </Typography>
    </div>
  );
}

export default PrivacyPolicies;
