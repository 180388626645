import { Link } from "react-router-dom";
// import { Facebook, Instagram } from "@mui/icons-material";
// import XIcon from "@mui/icons-material/X";
import { Box } from "@mui/material";

function Footer() {
  return (
    <footer className="footer">
      <Box className="footer-social-media">
        {/* <IconButton
          component="a"
          href="https://www.facebook.com"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: "var(--primary-color)" }}
        >
          <Facebook />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.x.com"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: "var(--primary-color)" }}
        >
          <XIcon />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: "var(--primary-color)" }}
        >
          <Instagram />
        </IconButton> */}
      </Box>
      <Box className="footer-links">
        <Link to="/terms-of-use" className="footer-link">
          Terms of Use
        </Link>
        <Link to="/privacy-policy" className="footer-link">
          Privacy Policy
        </Link>
      </Box>
    </footer>
  );
}

export default Footer;
