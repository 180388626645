import { Typography, Box } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";

function HomePage() {
  // Generate URL that includes a trigger parameter
  const waitlistUrl = `https://nuunapp.com?openWaitlist=true`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0 20px",
      }}
    >
      <Typography
        sx={{
          color: "var(--primary-color)",
          fontFamily: "passion one",
          fontSize: "4rem",
          marginTop: "200px",
        }}
      >
        COMING SOON
      </Typography>

      {/* Add QR Code section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "40px",
          gap: "20px",
        }}
      >
        <Typography
          sx={{
            color: "var(--secondary-color)",
            fontFamily: "passion one",
            fontSize: "1.5rem",
            textAlign: "center",
          }}
        >
          Scan to Join Our Waitlist
        </Typography>

        <QRCodeSVG
          value={waitlistUrl}
          size={200}
          level="H"
          includeMargin={true}
          style={{
            padding: "10px",
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        />
      </Box>
    </div>
  );
}

export default HomePage;
