import React from "react";
import { Typography, List, ListItem, ListItemText } from "@mui/material";

function TermsOfUse() {
  return (
    <div className="policy-container">
      <Typography variant="h4" gutterBottom className="terms-top-header">
        Terms of Use
      </Typography>

      <Typography className="terms-body-text">
        Please read these “Terms of Use” carefully as they set out the basis on
        which you are allowed to use the mobile app (the “App”) and the
        services, features, content, applications, websites, or widgets (the
        “Services”) offered by NUUN Group (“nuun,” “we,” “us” or “our”). If you
        have any questions about the App or Services, please contact us at
        admin@nuunapp.com before registering to use the App or the Services.
      </Typography>

      <Typography className="terms-body-text">
        Last Updated: 08/22/2024
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        1. Acceptance of Terms
      </Typography>
      <Typography className="terms-body-text">
        By downloading, accessing, or using the NUUN app (the "App"), you agree
        to comply with and be bound by these Terms of Use ("Terms"). If you do
        not agree to these Terms, please do not use the App.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        2. Use of the App
      </Typography>
      <Typography className="terms-body-text">
        NUUN is provided by Asana Entertainment LTD and is intended for
        personal, non-commercial use. You agree to use the App in accordance
        with all applicable laws and regulations.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        3. User Accounts
      </Typography>
      <Typography className="terms-body-text">
        To access certain features of the App, you may need to create an
        account. You are responsible for maintaining the confidentiality of your
        account information and for all activities that occur under your
        account. You agree to notify us immediately of any unauthorized use of
        your account.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        5. Event Creation and Participation
      </Typography>
      <Typography className="terms-body-text">
        Users may create and participate in events through the App. You agree
        that you are responsible for ensuring that any event you create or
        participate in complies with all applicable laws, rules, and
        regulations.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        5. Content Ownership and Use
      </Typography>
      <Typography className="terms-body-text">
        All content provided by NUUN, including text, graphics, logos, and
        software, is the property of Asana Entertainment LTD or its licensors
        and is protected by intellectual property laws. You may not use,
        reproduce, or distribute any content from the App without our prior
        written permission.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        6. User-Generated Content
      </Typography>
      <Typography className="terms-body-text">
        You retain ownership of any content you submit, post, or display on or
        through the App. However, by submitting content, you grant Asana
        Entertainment LTD a worldwide, non-exclusive, royalty-free license to
        use, reproduce, modify, and distribute your content in connection with
        the operation of the App.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        7. Prohibited Conduct
      </Typography>
      <Typography className="terms-body-text">You agree not to:</Typography>
      <List className="terms-body-text">
        <ListItem>
          <ListItemText primary="Use the App for any illegal or unauthorized purpose." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Upload, post, or transmit any content that is unlawful, harmful, or otherwise objectionable." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Engage in any activity that interferes with or disrupts the App or its servers." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Attempt to gain unauthorized access to any part of the App, other accounts, or computer systems." />
        </ListItem>
      </List>

      <Typography variant="h5" gutterBottom className="terms-header">
        8. Termination
      </Typography>
      <Typography className="terms-body-text">
        We reserve the right to terminate or suspend your access to the App at
        any time, with or without notice, for any reason, including your
        violation of these Terms.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        9. Disclaimer of Warranties
      </Typography>
      <Typography className="terms-body-text">
        The App is provided on an "as is" and "as available" basis. Asana
        Entertainment LTD makes no warranties, express or implied, regarding the
        App, including but not limited to its reliability, accuracy, or
        suitability for a particular purpose.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        10. Limitation of Liability
      </Typography>
      <Typography className="terms-body-text">
        To the fullest extent permitted by law, Asana Entertainment LTD shall
        not be liable for any damages arising out of or in connection with your
        use of or inability to use the App.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        11. Changes to the Terms
      </Typography>
      <Typography className="terms-body-text">
        We may update these Terms from time to time. We will notify you of any
        changes by posting the new Terms on this page and updating the "Last
        Updated" date.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        12. Governing Law
      </Typography>
      <Typography className="terms-body-text">
        These Terms shall be governed by and construed in accordance with the
        laws of [Your Country/State], without regard to its conflict of law
        provisions.
      </Typography>

      <Typography variant="h5" gutterBottom className="terms-header">
        13. Contact Us
      </Typography>
      <Typography className="terms-body-text">
        If you have any questions or concerns about these Terms, please contact
        us at: admin@nuunapp.com
      </Typography>
      <Typography className="terms-body-text">
        Asana Entertainment LTD
      </Typography>
    </div>
  );
}

export default TermsOfUse;
