import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { signOut } from "./auth";
import { useState, useEffect } from "react";

import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebase";

function NavBar() {
  const { userLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openWaitlist, setOpenWaitlist] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("openWaitlist") === "true") {
      setOpenWaitlist(true);
      window.history.replaceState({}, "", window.location.pathname);
    }
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOut();
    handleClose();
    navigate("/");
  };

  const handleWaitlistOpen = () => setOpenWaitlist(true);
  const handleWaitlistClose = () => {
    setOpenWaitlist(false);
    setPhoneNumber("");
    setPhoneError("");
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    if (value && !validatePhoneNumber(value)) {
      setPhoneError("Please enter a valid phone number");
    } else {
      setPhoneError("");
    }
  };

  const handlePhoneSubmit = async () => {
    if (!phoneNumber) {
      setPhoneError("Phone number is required");
      return;
    }

    if (!validatePhoneNumber(phoneNumber)) {
      setPhoneError("Please enter a valid phone number");
      return;
    }

    try {
      await addDoc(collection(db, "waitlist"), {
        phoneNumber,
        timestamp: new Date(),
      });
      alert("Successfully joined the waitlist!");
      handleWaitlistClose();
    } catch (error) {
      console.error("Error adding to waitlist:", error);
      alert("Error joining waitlist. Please try again.");
    }
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return phoneRegex.test(number);
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: "transparent", boxShadow: "none" }}
      >
        <Toolbar>
          <Link to="/">
            <img
              alt="logo"
              src={require("../src/assets/FullnuunLogoV2.png")}
              className="navbar-logo"
            />
          </Link>
          <Box sx={{ flexGrow: 1 }} />

          {/* Desktop View */}
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            {userLoggedIn && (
              <>
                <Button
                  component={Link}
                  to="/event-form"
                  sx={{ color: "var(--secondary-color)" }}
                >
                  Add Event
                </Button>
                <Button
                  component={Link}
                  to="/events"
                  sx={{ color: "var(--secondary-color)" }}
                >
                  Events
                </Button>
              </>
            )}

            {userLoggedIn ? (
              <Button
                sx={{ color: "var(--secondary-color)" }}
                onClick={handleLogout}
              >
                Logout
              </Button>
            ) : (
              <Button
                sx={{ color: "var(--secondary-color)" }}
                component={Link}
                to="/login"
              >
                Login
              </Button>
            )}

            <Button
              sx={{
                color: "var(--secondary-color)",
                marginRight: 2,
                fontFamily: "passion one",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              onClick={handleWaitlistOpen}
            >
              Join Waitlist
            </Button>
          </Box>

          {/* Mobile View */}
          <Box sx={{ display: { xs: "flex", sm: "none" } }}>
            {userLoggedIn ? (
              <>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  sx={{ color: "var(--secondary-color)" }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/event-form"
                  >
                    Add Event
                  </MenuItem>
                  <MenuItem onClick={handleClose} component={Link} to="/events">
                    Events
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button
                  sx={{
                    color: "var(--secondary-color)",
                    marginRight: 1,
                    fontFamily: "passion one",
                    fontSize: "14px",
                  }}
                  onClick={handleWaitlistOpen}
                >
                  Join Waitlist
                </Button>
                <Button
                  sx={{ color: "var(--secondary-color)" }}
                  component={Link}
                  to="/login"
                >
                  Login
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Waitlist Dialog */}
      <Dialog
        open={openWaitlist}
        onClose={handleWaitlistClose}
        PaperProps={{
          sx: {
            borderRadius: 2,
            padding: 2,
            maxWidth: "400px",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontFamily: "passion one",
            color: "var(--secondary-color)",
            fontSize: "24px",
          }}
        >
          Join Our Waitlist
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
              padding: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                color: "var(--secondary-color)",
                fontFamily: "Arial",
                fontSize: "14px",
              }}
            >
              Enter your phone number below to join our waitlist
            </Typography>

            <TextField
              label="Phone Number"
              value={phoneNumber}
              onChange={handlePhoneChange}
              error={!!phoneError}
              helperText={phoneError}
              fullWidth
              margin="normal"
              placeholder="(123) 456-7890"
              InputProps={{
                sx: {
                  borderRadius: 1,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--secondary-color)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--secondary-color)",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "var(--secondary-color)",
                },
              }}
            />

            <Button
              variant="contained"
              onClick={handlePhoneSubmit}
              sx={{
                backgroundColor: "var(--secondary-color)",
                color: "white",
                fontFamily: "passion one",
                fontSize: "16px",
                padding: "8px 24px",
                borderRadius: 2,
                "&:hover": {
                  backgroundColor: "var(--secondary-color)",
                  opacity: 0.9,
                },
              }}
            >
              Join Waitlist
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default NavBar;
