import React, { useState } from "react";
import { Container, Typography, TextField, Button, Box } from "@mui/material";
import { signIn } from "./auth";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSingedIn, setIsSingedIn] = useState(false);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isSingedIn) {
      setIsSingedIn(true);
      const authorizedUser = await signIn(email, password);
      if (authorizedUser) {
        navigate("/event-form");
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          fontFamily={"passion one"}
          sx={{
            color: "var(--primary-color)",
            fontSize: "2rem",
          }}
        >
          SIGN IN
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            className="TextField"
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            className="TextField"
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              fontWeight: "bold",
              fontSize: 14,
              marginTop: 4,
              color: "var(--secondary-color)",
              backgroundColor: "var(--primary-color)",
              ":hover": {
                backgroundColor: "var(--primary-color)",
                color: "var(--secondary-color)",
              },
            }}
          >
            SIGN IN
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default LoginPage;
