import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import EventForm from "./EventForm";
import Events from "./Events";
import EditEventForm from "./EditEventForm";
import NavBar from "./NavBar";
import HomePage from "./HomePage";
import LoginPage from "./LoginPage";
import { LoadScript } from "@react-google-maps/api";
import { AuthProvider } from "./AuthContext";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicies from "./PrivacyPolicies";
import Footer from "./Footer";

function App() {
  return (
    <AuthProvider>
      <LoadScript
        googleMapsApiKey="AIzaSyB2VgHW2t5WOjBpcQyDE_E33L3Ooq02DJY"
        libraries={["places"]}
      >
        <div className="app">
          <Router>
            <NavBar />
            <Routes>
              <Route path="/" exact element={<HomePage />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/privacy-policy" element={<PrivacyPolicies />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/event-form" element={<EventForm />} />
              <Route path="/events" element={<Events />} />
              <Route
                path="/events/edit-event/:id"
                element={<EditEventForm />}
              />
            </Routes>
            <Footer />
          </Router>
        </div>
      </LoadScript>
    </AuthProvider>
  );
}

export default App;
